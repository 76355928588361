@font-face {
    font-family: "Roboto Condensed";
    src: url("/fonts/robotocondensed/robotocondensed-light.eot");
    src: url("/fonts/robotocondensed/robotocondensed-light.eot?#iefix") format("embedded-opentype"),
         url("/fonts/robotocondensed/robotocondensed-light.woff2") format("woff2"),
         url("/fonts/robotocondensed/robotocondensed-light.woff") format("woff"),
         url("/fonts/robotocondensed/robotocondensed-light.ttf") format("truetype"),
         url("/fonts/robotocondensed/robotocondensed-light.svg#robotocondensed-light") format("svg");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Roboto Condensed";
    src: url("/fonts/robotocondensed/robotocondensed-lightitalic.eot");
    src: url("/fonts/robotocondensed/robotocondensed-lightitalic.eot?#iefix") format("embedded-opentype"),
         url("/fonts/robotocondensed/robotocondensed-lightitalic.woff2") format("woff2"),
         url("/fonts/robotocondensed/robotocondensed-lightitalic.woff") format("woff"),
         url("/fonts/robotocondensed/robotocondensed-lightitalic.ttf") format("truetype"),
         url("/fonts/robotocondensed/robotocondensed-lightitalic.svg#robotocondensed-lightitalic") format("svg");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Roboto Condensed";
    src: url("/fonts/robotocondensed/robotocondensed-regular.eot");
    src: url("/fonts/robotocondensed/robotocondensed-regular.eot?#iefix") format("embedded-opentype"),
         url("/fonts/robotocondensed/robotocondensed-regular.woff2") format("woff2"),
         url("/fonts/robotocondensed/robotocondensed-regular.woff") format("woff"),
         url("/fonts/robotocondensed/robotocondensed-regular.ttf") format("truetype"),
         url("/fonts/robotocondensed/robotocondensed-regular.svg#robotocondensed-regular") format("svg");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Roboto Condensed";
    src: url("/fonts/robotocondensed/robotocondensed-italic.eot");
    src: url("/fonts/robotocondensed/robotocondensed-italic.eot?#iefix") format("embedded-opentype"),
         url("/fonts/robotocondensed/robotocondensed-italic.woff2") format("woff2"),
         url("/fonts/robotocondensed/robotocondensed-italic.woff") format("woff"),
         url("/fonts/robotocondensed/robotocondensed-italic.ttf") format("truetype"),
         url("/fonts/robotocondensed/robotocondensed-italic.svg#robotocondensed-italic") format("svg");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "Roboto Condensed";
    src: url("/fonts/robotocondensed/robotocondensed-bold.eot");
    src: url("/fonts/robotocondensed/robotocondensed-bold.eot?#iefix") format("embedded-opentype"),
         url("/fonts/robotocondensed/robotocondensed-bold.woff2") format("woff2"),
         url("/fonts/robotocondensed/robotocondensed-bold.woff") format("woff"),
         url("/fonts/robotocondensed/robotocondensed-bold.ttf") format("truetype"),
         url("/fonts/robotocondensed/robotocondensed-bold.svg#robotocondensed-bold") format("svg");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Roboto Condensed";
    src: url("/fonts/robotocondensed/robotocondensed-bolditalic.eot");
    src: url("/fonts/robotocondensed/robotocondensed-bolditalic.eot?#iefix") format("embedded-opentype"),
         url("/fonts/robotocondensed/robotocondensed-bolditalic.woff2") format("woff2"),
         url("/fonts/robotocondensed/robotocondensed-bolditalic.woff") format("woff"),
         url("/fonts/robotocondensed/robotocondensed-bolditalic.ttf") format("truetype"),
         url("/fonts/robotocondensed/robotocondensed-bolditalic.svg#robotocondensed-bolditalic") format("svg");
    font-weight: 700;
    font-style: italic;
}
