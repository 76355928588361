@font-face {
    font-family: "Roboto";
    src: url("/fonts/roboto/roboto-thin.eot");
    src: url("/fonts/roboto/roboto-thin.eot?#iefix") format("embedded-opentype"),
         url("/fonts/roboto/roboto-thin.woff2") format("woff2"),
         url("/fonts/roboto/roboto-thin.woff") format("woff"),
         url("/fonts/roboto/roboto-thin.ttf") format("truetype"),
         url("/fonts/roboto/roboto-thin.svg#roboto-thin") format("svg");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("/fonts/roboto/roboto-thinitalic.eot");
    src: url("/fonts/roboto/roboto-thinitalic.eot?#iefix") format("embedded-opentype"),
         url("/fonts/roboto/roboto-thinitalic.woff2") format("woff2"),
         url("/fonts/roboto/roboto-thinitalic.woff") format("woff"),
         url("/fonts/roboto/roboto-thinitalic.ttf") format("truetype"),
         url("/fonts/roboto/roboto-thinitalic.svg#roboto-thinitalic") format("svg");
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: "Roboto";
    src: url("/fonts/roboto/roboto-light.eot");
    src: url("/fonts/roboto/roboto-light.eot?#iefix") format("embedded-opentype"),
         url("/fonts/roboto/roboto-light.woff2") format("woff2"),
         url("/fonts/roboto/roboto-light.woff") format("woff"),
         url("/fonts/roboto/roboto-light.ttf") format("truetype"),
         url("/fonts/roboto/roboto-light.svg#roboto-light") format("svg");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("/fonts/roboto/roboto-lightitalic.eot");
    src: url("/fonts/roboto/roboto-lightitalic.eot?#iefix") format("embedded-opentype"),
         url("/fonts/roboto/roboto-lightitalic.woff2") format("woff2"),
         url("/fonts/roboto/roboto-lightitalic.woff") format("woff"),
         url("/fonts/roboto/roboto-lightitalic.ttf") format("truetype"),
         url("/fonts/roboto/roboto-lightitalic.svg#roboto-lightitalic") format("svg");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Roboto";
    src: url("/fonts/roboto/roboto-regular.eot");
    src: url("/fonts/roboto/roboto-regular.eot?#iefix") format("embedded-opentype"),
         url("/fonts/roboto/roboto-regular.woff2") format("woff2"),
         url("/fonts/roboto/roboto-regular.woff") format("woff"),
         url("/fonts/roboto/roboto-regular.ttf") format("truetype"),
         url("/fonts/roboto/roboto-regular.svg#roboto-regular") format("svg");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("/fonts/roboto/roboto-italic.eot");
    src: url("/fonts/roboto/roboto-italic.eot?#iefix") format("embedded-opentype"),
         url("/fonts/roboto/roboto-italic.woff2") format("woff2"),
         url("/fonts/roboto/roboto-italic.woff") format("woff"),
         url("/fonts/roboto/roboto-italic.ttf") format("truetype"),
         url("/fonts/roboto/roboto-italic.svg#roboto-italic") format("svg");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "Roboto";
    src: url("/fonts/roboto/roboto-medium.eot");
    src: url("/fonts/roboto/roboto-medium.eot?#iefix") format("embedded-opentype"),
         url("/fonts/roboto/roboto-medium.woff2") format("woff2"),
         url("/fonts/roboto/roboto-medium.woff") format("woff"),
         url("/fonts/roboto/roboto-medium.ttf") format("truetype"),
         url("/fonts/roboto/roboto-medium.svg#roboto-medium") format("svg");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("/fonts/roboto/roboto-mediumitalic.eot");
    src: url("/fonts/roboto/roboto-mediumitalic.eot?#iefix") format("embedded-opentype"),
         url("/fonts/roboto/roboto-mediumitalic.woff2") format("woff2"),
         url("/fonts/roboto/roboto-mediumitalic.woff") format("woff"),
         url("/fonts/roboto/roboto-mediumitalic.ttf") format("truetype"),
         url("/fonts/roboto/roboto-mediumitalic.svg#roboto-mediumitalic") format("svg");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "Roboto";
    src: url("/fonts/roboto/roboto-bold.eot");
    src: url("/fonts/roboto/roboto-bold.eot?#iefix") format("embedded-opentype"),
         url("/fonts/roboto/roboto-bold.woff2") format("woff2"),
         url("/fonts/roboto/roboto-bold.woff") format("woff"),
         url("/fonts/roboto/roboto-bold.ttf") format("truetype"),
         url("/fonts/roboto/roboto-bold.svg#roboto-bold") format("svg");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("/fonts/roboto/roboto-bolditalic.eot");
    src: url("/fonts/roboto/roboto-bolditalic.eot?#iefix") format("embedded-opentype"),
         url("/fonts/roboto/roboto-bolditalic.woff2") format("woff2"),
         url("/fonts/roboto/roboto-bolditalic.woff") format("woff"),
         url("/fonts/roboto/roboto-bolditalic.ttf") format("truetype"),
         url("/fonts/roboto/roboto-bolditalic.svg#roboto-bolditalic") format("svg");
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: "Roboto";
    src: url("/fonts/roboto/roboto-black.eot");
    src: url("/fonts/roboto/roboto-black.eot?#iefix") format("embedded-opentype"),
         url("/fonts/roboto/roboto-black.woff2") format("woff2"),
         url("/fonts/roboto/roboto-black.woff") format("woff"),
         url("/fonts/roboto/roboto-black.ttf") format("truetype"),
         url("/fonts/roboto/roboto-black.svg#roboto-black") format("svg");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("/fonts/roboto/roboto-blackitalic.eot");
    src: url("/fonts/roboto/roboto-blackitalic.eot?#iefix") format("embedded-opentype"),
         url("/fonts/roboto/roboto-blackitalic.woff2") format("woff2"),
         url("/fonts/roboto/roboto-blackitalic.woff") format("woff"),
         url("/fonts/roboto/roboto-blackitalic.ttf") format("truetype"),
         url("/fonts/roboto/roboto-blackitalic.svg#roboto-blackitalic") format("svg");
    font-weight: 900;
    font-style: italic;
}
