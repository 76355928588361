body {
    -webkit-font-smoothing: auto;
}

.pagination {
    margin: 0;
}

h4 {
    font-weight: 300;
}

h2 .btn {
    margin-left: 10px;
}

.required {
    color: red;
}

.sidebar-collapsed .header-left .topnav .form-control {
    margin: 7px 0 0 8px;
}

.header-left .topnav .btn {
    margin: 7px 0 0;
    float: left;
}

.sidebar-collapsed .header-left .topnav .btn {
    margin: 7px 0 0 8px;
}

.main-content .page-content {
    overflow: visible;
}

.main-content .page-content .panel .panel-header h3.big {
     font-size: 150%;
 }

.main-content .page-content .panel .panel-header .print-btn {
   margin-right: 2em;
}


.main-content .page-content .panel h3.member-name {
    margin-top: 10px;
}

.main-content .page-content .panel h3.member-name {
    margin-top: 10px;
}
.fixed-topbar .topbar {
    z-index: 999;
}

.nav > li > a.toggle_fullscreen {
    display: none;
}

.header-left .topnav .form-control {
    margin: 7px 0 0;
    width: 130px;
    float: left;
}
.panel-heading{
    border-bottom: 1px solid #ccc !important;
}

.pricing-table .plan .description .p-item:last-child .plan-item {
    border-bottom: 0;
}

.pricing-table .plan .description > div:nth-last-child(2) .plan-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.pricing-table.num-plan-full .plan {
    width: 100%;
}

.sidebar .logopanel {
    padding: .6em .6em 0 .6em;
}

.sidebar .sidebar-inner .nav-sidebar li i {
    text-align: center;
}

.sidebar .sidebar-inner .nav-sidebar > li i.spin:before {
    display: inline-block;
    -webkit-transition: -webkit-transform 1s ease;
    -moz-transition: -moz-transform 1s ease;
    -ms-transition: -ms-transform 1s ease;
    -o-transition: -webkit-transform 1s ease;
    transition: transform 1s ease;

    -webkit-transform:rotate(0deg);
    -moz-transform:rotate(0deg);
    -ms-transform:rotate(0deg);
    -o-transform:rotate(0deg);
    transform:rotate(0deg)
}

.sidebar .sidebar-inner .nav-sidebar > li > a span:not(.badge) {
    opacity: 1;
}

.sweet-alert {
    font-family: "Roboto";
}

.main-content .panel .panel-heading h3 {
    font-size: 18px;
    color:#000000;
    font-weight: 900;
}
.main-content .page-content .panel table .btn-group {
    z-index: auto;
}

.main-content .page-content .panel .panel-header h3 {
    font-weight: 900;
    font-size: 18px;
    color:#000000;
}

.main-content .page-content .user-widget {
    margin-top: 0;
}

.news-widget h4 {
    line-height: 1.4;
}

.clear-xs-left  { clear: left !important; }
.clear-xs-right { clear: right !important; }
.clear-xs-both  { clear: both !important; }
.clear-xs-none  { clear: none !important; }

/* -------------------------------------------------------------------------- */
/* > Preisrechner: Verbrauchs-Slider */

.form-module--usage-slider > div,
.form-module--usage-slider[data-sparte="strom"] [data-sparte="gas"],
.form-module--usage-slider[data-sparte="gas"] [data-sparte="strom"] {
    display: none;
}

.form-module--usage-slider[data-sparte="strom"] > div,
.form-module--usage-slider[data-sparte="gas"] > div {
    display: table;
}

.form-module--usage-slider > div > div {
    display: table-cell;
    vertical-align: middle;
}

.form-module--usage-slider > div > div + div {
    padding-left: 1.5em;
    white-space: nowrap;
    width: 100%;
}

.usage-slider--silhouettes {
    white-space: nowrap;
    margin: 0 -0.5em;
}

.usage-slider--silhouettes label {
    max-width: none;
    margin-bottom: 0;
    padding: 0 0.75em;
    position: relative;
    margin-left: -0.25em;
}

.usage-slider--silhouettes label.silhouette-1 {
    margin-left: 0;
}

.usage-slider--silhouettes input {
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    cursor: pointer;
}

.usage-slider--silhouettes input:checked {
    cursor: default;
}

.usage-slider--silhouettes svg {
    height: 2.5em;
    width: auto; /* Der bekackte IE rafft das leider NICHT */
    fill-opacity: 0.4;
    fill: #000;
    display: block;
}

.usage-slider--silhouettes .silhouette-1[data-sparte="strom"] svg { width: 1.2857142857em; }
.usage-slider--silhouettes .silhouette-2[data-sparte="strom"] svg { width: 3.0000000000em; }
.usage-slider--silhouettes .silhouette-3[data-sparte="strom"] svg { width: 2.5714285714em; }
.usage-slider--silhouettes .silhouette-4[data-sparte="strom"] svg { width: 2.8571428571em; }

.usage-slider--silhouettes .silhouette-1[data-sparte="gas"] svg { width: 2.1428571428em; }
.usage-slider--silhouettes .silhouette-2[data-sparte="gas"] svg { width: 2.7857142857em; }
.usage-slider--silhouettes .silhouette-3[data-sparte="gas"] svg { width: 2.2142857142em; }
.usage-slider--silhouettes .silhouette-4[data-sparte="gas"] svg { width: 2.7857142857em; }

.usage-slider--silhouettes input:hover + svg,
.usage-slider--silhouettes input:checked + svg {
    fill-opacity: 0.6;
}

.usage-slider--silhouettes input:checked + svg,
.usage-slider--silhouettes input:checked:hover + svg {
    fill-opacity: 1;
}

.usage-slider--control {
    margin-top: 0.75em;
}

.usage-slider--arrow {
    display: none;
}

.form-module--usage-slider .form-control {
    width: 100%;
    text-align: center;
}

.form-module--usage-slider .noUi-horizontal {
    height: 5px;
}

.form-module--usage-slider .noUi-origin,
.form-module--usage-slider .noUi-target,
.form-module--usage-slider .noUi-handle {
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    cursor: pointer;
}

.form-module--usage-slider .noUi-target.noUi-connect,
.form-module--usage-slider .noUi-background,
.form-module--usage-slider .noUi-handle {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.form-module--usage-slider .noUi-background {
    background-color: #D9D9D9;
}

.form-module--usage-slider .noUi-handle {
    background: none;
    width: 11px;
    height: 15px;
    left: -6px;
    top: -10px;
}

.form-module--usage-slider .noUi-handle:before,
.form-module--usage-slider .noUi-handle:after {
    display: none;
}

.form-module--usage-slider .noUi-handle svg {
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    width: 100%;
    max-height: 100%;
    margin: auto;
}

@media (min-width: 992px) and (max-width: 1279px), (max-width: 479px) {
    .form-module--usage-slider > div > div + div {
        display: table-row;
    }
}

/* < Preisrechner: Verbrauchs-Slider */
/* -------------------------------------------------------------------------- */

@media (min-width: 768px) {

    /* sm */

    .main-content .page-content .panel .panel-header h3.big {
        font-size: 200%;
    }

    .nav > li > a.toggle_fullscreen {
        display: inline-block;
    }

    .header-left .topnav .form-control {
        width: 300px;
    }

    .clear-sm-left  { clear: left !important; }
    .clear-sm-right { clear: right !important; }
    .clear-sm-both  { clear: both !important; }
    .clear-sm-none  { clear: none !important; }

}

.main-content .page-content .panel-accordion h3 {
    margin: 0;
}

.panel-accordion .panel-body a {
    width: auto;
    display: inline;
    background-color: transparent;
    padding: 0;
}

.form-horizontal .prepend-icon i {
    left: 4px;
}

/* sweet alert */

.sweet-alert {
    border-radius: 0;
    background: #f5f5f5;
}

.sweet-alert h2 {
    font-size: 130%;
    margin: 0;
}

.sweet-alert fieldset {
    margin: 0;
    padding: 0;
}

.sweet-alert .sa-error-container {
    display: none;
}

.sweet-alert .sa-button-container .confirm {
    float: right;
}

.sweet-alert .sa-button-container button {
    box-shadow: 0 0 0 transparent !important;
}

/* eof sweet alert */

.user-widget p i {
    display: inline-block;
    margin-right: 5px;
    width: 15px;
    text-align: center;
}

.jquery-clock {
    display: none;
}

.stacktable tr:first-child {
    display: none;
}

.btn-action li {
    list-style: none;
    float: left;
    width: 100%;
}

.st-val {
    max-width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.st-key {
    opacity: 0.5;
    text-align: left;
}

.page-profil .profil-header .profil-img img {
    height: auto;
    width: 100%;
}

.profil-info-image {
    float: left;
    margin-right: 15px;
}

.profil-info-image img {
    height: 65px;
    width: auto;
}

.profil-content .item .user .info a {
    color: #fff;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 270px;
}

.profil-content .item .user .info a:hover {
    text-decoration: underline;
}

.page-profil .profil-content .item .more .comments img {
    margin-top: 20px;
}

.page-profil .profil-content .item .share a {
    color: #fff;
}

.page-profil .profil-header .profil-info a {
    color: #a4a4a4;
}

.page-profil .profil-right {
    position: relative;
}

.first-sidebar-element {
    margin: 20px 0;
    float: left;
}

.page-profil .profil-header {
    height: auto;
}

.page-profil .profil-header .col-map {
    height: 200px;
}

.page-profil .profil-header .header-name {
    height: auto;
}

.page-profil .profil-header .moments {
    float: left;
    margin-top: 15px;
    width: 100%;
    height: auto;
}

.banner-image .panel-content img {
    max-width: 100%;
    max-height: 20rem;
}

.profil-info-image {
    float: left;
    margin: 0;
    padding: 15px 0;
    text-align: center;
    width: 100%;
}

.page-profil .profil-content .item .user {
    height: 180px;
}

.page-profil .profil-header .profil-info {
    float: left;
    width: 100%;
    padding: 0;
}

.page-profil .profil-content .item .user .location span {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 120px;
}

.page-profil .profil-content .item .user .location,
.page-profil .profil-content .item .user .date {
    line-height: 1;
}

.page-profil .profil-content .item .more .like,
.page-profil .profil-content .item .more .more-comments,
.page-profil .profil-content .item .more .more-share {
    background: #f7f7f7 none repeat scroll 0 0;
    color: #828282;
    cursor: pointer;
    font-size: 14px;
    padding: 15px 0 5px;
    position: relative;
    text-align: center;
}

@-moz-keyframes spinner-loader {
    0% {
        -moz-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -moz-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-webkit-keyframes spinner-loader {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes spinner-loader {
    0% {
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
/* :not(:required) hides this rule from IE9 and below */
.spinner-loader:not(:required) {
    -moz-animation: spinner-loader 1500ms infinite linear;
    -webkit-animation: spinner-loader 1500ms infinite linear;
    animation: spinner-loader 1500ms infinite linear;
    -moz-border-radius: 0.5em;
    -webkit-border-radius: 0.5em;
    border-radius: 0.5em;
    -moz-box-shadow: rgba(0, 0, 51, 0.3) 1.5em 0 0 0, rgba(0, 0, 51, 0.3) 1.1em 1.1em 0 0, rgba(0, 0, 51, 0.3) 0 1.5em 0 0, rgba(0, 0, 51, 0.3) -1.1em 1.1em 0 0, rgba(0, 0, 51, 0.3) -1.5em 0 0 0, rgba(0, 0, 51, 0.3) -1.1em -1.1em 0 0, rgba(0, 0, 51, 0.3) 0 -1.5em 0 0, rgba(0, 0, 51, 0.3) 1.1em -1.1em 0 0;
    -webkit-box-shadow: rgba(0, 0, 51, 0.3) 1.5em 0 0 0, rgba(0, 0, 51, 0.3) 1.1em 1.1em 0 0, rgba(0, 0, 51, 0.3) 0 1.5em 0 0, rgba(0, 0, 51, 0.3) -1.1em 1.1em 0 0, rgba(0, 0, 51, 0.3) -1.5em 0 0 0, rgba(0, 0, 51, 0.3) -1.1em -1.1em 0 0, rgba(0, 0, 51, 0.3) 0 -1.5em 0 0, rgba(0, 0, 51, 0.3) 1.1em -1.1em 0 0;
    box-shadow: rgba(0, 0, 51, 0.3) 1.5em 0 0 0, rgba(0, 0, 51, 0.3) 1.1em 1.1em 0 0, rgba(0, 0, 51, 0.3) 0 1.5em 0 0, rgba(0, 0, 51, 0.3) -1.1em 1.1em 0 0, rgba(0, 0, 51, 0.3) -1.5em 0 0 0, rgba(0, 0, 51, 0.3) -1.1em -1.1em 0 0, rgba(0, 0, 51, 0.3) 0 -1.5em 0 0, rgba(0, 0, 51, 0.3) 1.1em -1.1em 0 0;
    display: inline-block;
    font-size: 10px;
    width: 1em;
    height: 1em;
    margin: 1.5em;
    overflow: hidden;
    text-indent: 100%;
}

@media (min-width: 768px) {

    .page-profil .profil-header .profil-img {
        height: 200px;
    }

    .profil-info-image {
        width: auto;
        padding-right: 15px;
    }

    .page-profil .profil-header .name {
        padding-top: 15px;
    }

    .page-profil .profil-header .profil-info {
        padding-right: 15px;
        width: auto;
    }

}

@media (min-width: 801px) {

    .stacktable tr:first-child {
        display: table-row;
    }

    .stacktable th:last-child,
    .stacktable td:last-child {
        width: 170px;
    }

}

@media (min-width: 992px) {

    /* md */

    .page-profil .profil-right {
        position: absolute;
    }

    .clear-md-left  { clear: left !important; }
    .clear-md-right { clear: right !important; }
    .clear-md-both  { clear: both !important; }
    .clear-md-none  { clear: none !important; }

}

@media (min-width: 1200px) {

    /* lg */

    .jquery-clock {
        display: block;
    }

    .page-profil .profil-header .header-name {
        padding: 0;
    }

    .page-profil .profil-header .moments {
        margin-top: 0;
        width: 25%;
    }

    .page-profil .profil-header {
        height: 295px;
    }

    .clear-lg-left  { clear: left !important; }
    .clear-lg-right { clear: right !important; }
    .clear-lg-both  { clear: both !important; }
    .clear-lg-none  { clear: none !important; }

}

@media (min-width: 1700px) {

    .btn-action li {
        width: auto;
    }

    .stacktable th:last-child,
    .stacktable td:last-child {
        width: 760px;
    }

}

.width{z-index:260;position:relative}
@media(min-width:300px){.width{z-index:300}}
@media(min-width:400px){.width{z-index:400}}
@media(min-width:501px){.width{z-index:501}}
@media(min-width:630px){.width{z-index:630}}
@media(min-width:693px){.width{z-index:693}}
@media(min-width:783px){.width{z-index:783}}
@media(min-width:992px){.width{z-index:992}}
@media(min-width:1024px){.width{z-index:1024}}
@media(min-width:1400px){.width{z-index:1400}}


.btn-brand {
    box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.24) inset;
    transition: opacity 0.2s ease 0s, background-color 0.2s ease 0s, color 0.2s ease 0s;
}

.btn-brand:hover,
.btn-brand:active,
.btn-brand:focus {
    opacity: 0.9;
}

.profile-picture {
    border-radius: 50%;
    max-width: 70%;
    max-height: 70%;
}

body.account2 {
    display: table;
    height: 100%;
    text-align: center;
    width: 100%;
}

.account-info h1 {
    font-size: 140%;
    line-height: 1.4;
    margin: 30px 0 0;
    text-align: center;
}

.account-info ul li i {
    width: 30px;
}

.account2 .account-form h2 {
    font-family: "Roboto";
    font-size: 15px;
    font-weight: 100;
    margin-bottom: 20px;
    margin-top: 0;
    text-align: center;
    text-transform: uppercase;
}

.account2 .account-form h2 strong {
    font-family: "Roboto";
    font-weight: 800;
}

.account2 .submits {
    overflow: hidden;
}

.account2 .account-form .forgot-password {
    display: block;
    float: left;
    color: #a7a7a7;
    cursor: pointer;
}

.account2 .account-form .forgot-password:hover {
    color: #838383;
}

.btn-block i {
    margin-top: 0.05em;
    position: relative;
    z-index: 0;
}

.btn.btn-g {
    background-color: #D95232;
    color: #fff;
}

.btn.btn-g:hover {
    background-color: rgba(217, 82, 50, 0.9);
}

.login-with {
    color: #a7a7a7;
    display: inline-block;
    margin: 0 0 10px;
}

.account2 {
    background: #1a4a84;
}

.account2 .account-info {
    display: block;
    padding: 20px;
}

.account2 .account-info ul {
    list-style: outside none none;
    padding: 20px;
}

#login-block {
    float: left;
    margin: 20px 0;
}

.login-block-inner {
    display: block;
}

#login-block .login-block-inner .account-info .account-logo img {
    max-width: 240px;
}

.account-copyright {
    float: left;
    width: 100%;
}

.account2 .account-form .btn.btn-square.btn-block {
    float: left;
    margin: 5px 0;
    padding: 5px 10px;
    width: 100%;
}

.account2 .submits button.btn {
    margin: 10px 0 0;
    width: 100%;
}

.account-form .clearfix.m-t-60 {
    margin-top: 0 !important;
}

.account-form .clearfix.m-t-60 p {
    text-align: center;
    width: 100%;
}

.form-register {
    display: none;
}

.alert {
    margin: 0 auto 40px;
    max-width: 500px;
}

@media (min-width: 481px) {

    .account2 .account-form {
        min-width: 370px;
    }

}

@media (min-width: 768px) {

    /* sm */

    .account2 .account-info {
        display: table-cell;
    }

    #login-block {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        width: 100%;
        float: none;
    }

    .login-block-inner {
        display: inline-block;
    }

    .account2 .account-form .btn.btn-square.btn-block {
        width: auto;
        margin: 5px;
    }

    .account2 .submits button.btn {
        float: right;
        margin: 0;
        width: auto;
    }

    .account-form .clearfix.m-t-60 {
        margin-top: 60px !important;
    }

    .account-form .clearfix.m-t-60 p {
        width: auto;
    }
}

body#email-template .logo {
    text-align: center;
}

body#email-template .content {
    border-radius: 5px;
}

body#email-template .content .partner {
    text-align: center;
}

body#email-template td {
    padding: 20px;
}

body#email-template .social-icons li {
    list-style-type: none;
    display: inline;
}

.account .backstretch:before,
.account2 .backstretch:before {
    content: normal;
}

.nobr {
    white-space: nowrap;
}

.account2 .social-btn {
  color: #FFF;
}

.account2 .social-btn a,
.account2 .social-btn a:hover {
  color: inherit;
}

.dashboard .panel {
    display: inline-block;
    float: left;
    margin: 10px;
}
.padding-side-1em {
    padding-left: 1em;
    padding-right: 1em;
}

@media (min-width: 1px) {
    .dashboard .panel {
        width: calc(100% - 20px);
    }
}

@media (min-width: 768px) {
    .dashboard .panel {
        width: calc(50% - 20px);
    }
}

@media (min-width: 1400px) {
    .dashboard .panel {
        width: calc(33.3333% - 20px);
    }

}

@media (max-width: 600px){
    .header h2 {
        display: block;
        float: none !important;
    }
    .header .pull-right{
        float: none !important;
        padding-top: 1em;
    }
    .header .pull-right a:first-child{
        margin-left: 0;
    }
}

@media (max-width: 480px){
    .header .pull-right a{
        display: block;
        margin-left: 0;
        margin-bottom: 5px;
    }
}

@media print {
    * {
        -webkit-transition: none !important;
        transition: none !important;
    }
    .main-content {
        min-height: auto;
    }
    .hide-on-print {
        display: none;
    }
    .page-content {
        margin-top: 0 !important;
        padding: 0 !important;
    }
    .page-content .panel-header {
        padding-top: 0;
    }
    .page-content .panel-header > .col-md-12 {
        padding-left: 0;
    }
    .panel .panel-header .price {
        font-size: 14px !important;
    }
    .panel .panel-header .price .amount {
        font-size: 24px !important;
    }
    .pba-always {
        page-break-after: auto;
        padding-top: 2em;
    }
    .pbi-avoid {
        page-break-inside: avoid;
    }
    .pba-allways:nth-child(1) {
        padding-top: 0;
    }
    .pb-always-chunks .pba-always:last-child {
        page-break-after: avoid;
    }
    body,
    .main-content,
    .page-content {
        background-color: transparent;
    }
}

.print-portrait {
    display: none;
}

@media print and (orientation: portrait){
    .nobr.br-print-portrait {
        whitespace: normal !important;
    }
    .print-portrait {
        display: inherit !important;
    }
    .panel * {
        font-size: .975em !important;
    }
    .panel h2 {
        font-size: 1.5em !important;
    }
    .pba-always {
        page-break-after: auto;
    }
    .pb-always-chunks .pba-always:last-child {
        page-break-after: avoid;
    }
}